<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        How well does the course make clear why you are doing what you're doing? (1) Not well 🡪 (5)
        Very well
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissSurveyQ4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: '1',
          value: 'one',
        },
        {
          text: '2',
          value: 'two',
        },
        {
          text: '3',
          value: 'three',
        },
        {
          text: '4',
          value: 'four',
        },
        {
          text: '5',
          value: 'five',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
